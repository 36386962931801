import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../Layout/Layout"
import StyledBackgroundImageFull from "../BackgroundImageFull/BackgroundImageFull"
import { Helmet } from "react-helmet"
import SectionTransition from "../SectionTransition/SectionTransition"
import CodeBlock from "../CodeBlock/CodeBlock"


const PostPageLayout = ({ data: { mdx } }) => {
  const [codeLibs, setCodeLibs] = useState({});

// Provide common components here
  const shortcodes = {
    Link,
    // pre: props => <div {...props} />,
    // code: <CodeBlock codeLibs={codeLibs} />
  };

  const handleCodeLibChange = (clKey, clVal) => {
    setCodeLibs(() => {
      return ({...codeLibs, [clKey]: clVal});
    });
  };

  return (
    <Layout>
      <Helmet>
        <title>Home | Alan Henson - Freelance Full-Stack Engineer</title>
      </Helmet>
      <PostPageLayoutStyles>
        <StyledBackgroundImageFull imgName={mdx.frontmatter.bgImage}/>
        <div className={'above-fold-container'}>
          {/*below: empty div for flexbox space-between hacks*/}
          {/*otherwise title wouldn't be in middle of page*/}
          <div/>
          <div className={'blog-title'}>{mdx.frontmatter.title}</div>
          <SectionTransition from={'rgba(95,98,96,0)'} to={'#E5E1DA'} static/>
        </div>
        <div className={'post-content'}>
          <MDXProvider components={shortcodes}>
            <MDXRenderer codeLibs={codeLibs} handleCodeLibChange={handleCodeLibChange}>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
        <SectionTransition from={'#E5E1DA'} to={'#5f6260'} static />
      </PostPageLayoutStyles>
    </Layout>
  )
}
export default PostPageLayout

const PostPageLayoutStyles = styled.div`
  .above-fold-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .blog-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 7vh 0 0 0;
    font-size: 2rem;
  }
  
  .post-content {
    background-color: #E5E1DA;
    font-size: 1rem;
    line-height: 2rem;
    padding: 3vh 7vw;
  }
  
  p {
    margin-bottom: 7vh;
  }
  
`;

export const pageQuery = graphql`
    query BlogPostQuery($id: String) {
        mdx(id: { eq: $id }) {
            id
            body
            frontmatter {
                title
                bgImage
            }
        }
    }
`